import { routes } from "config/routes";
import { useNavigate } from "react-router";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";
import Button from "../Button/Button";
import Loader from "../Icons/Loader";
import AccountCard from "./AccountCard";
import useCashBalance from "modules/common/hooks/useCashBalance";

const AccountCardContainer = () => {
  const { error, isLoading, accounts, cashBalances } = useCashBalance();

  if (error) {
    toastNotificationService.alert(
      "Cannot fetch accounts data",
      ToastTypeEnum.ERROR
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center">
          <Loader stroke="black" />
        </div>
      ) : accounts?.length ? (
        <div className="grid grid-cols-[repeat(4,_auto)] h-full overflow-y-scroll">
          {cashBalances?.map((balance, index) => {
            return (
              <AccountCard
                dataTestId={`account-${index + 1}`}
                key={balance.currency}
                balance={balance}
              />
            );
          })}
        </div>
      ) : (
        <NoAccounts />
      )}
    </>
  );
};

const NoAccounts = () => {
  const { accounts } = routes;
  const navigate = useNavigate();
  return (
    <section className="flex flex-col justify-between h-[200px] w-[500px] border border-gray-100 p-10 rounded-md">
      <h3>You currently do not have any connected accounts.</h3>
      <Button
        className="btn- py-3 text-primary-300 border border-primary-300"
        onClick={() => navigate(accounts.connect)}
      >
        Add Account
      </Button>
    </section>
  );
};

export default AccountCardContainer;
