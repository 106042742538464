import {
  MerchantAccountResponse,
  MerchantAccountStatus,
} from "@nestcoinco/onboard-api-gateway-api-client";
import payoutService from "api/payoutService";
import { calculateCashBalances } from "modules/accounts/api/calculateCashBalances";
import { useMemo } from "react";
import { useQuery } from "react-query";

export const getCashBalance = (accounts?: MerchantAccountResponse[]) => {
  if (accounts) {
    const accountsMap = calculateCashBalances(accounts);
    const result = Object.keys(accountsMap).map((currency: any) => {
      return {
        totalAmount: accountsMap[currency].amount,
        currency,
        totalCount: accountsMap[currency].count,
      };
    });
    return result;
  }
  return null;
};

const useCashBalance = () => {
  const { data, isLoading, error } = useQuery("paymentAccounts", () =>
    payoutService.getAccounts({ size: 20 })
  );

  const accounts = data?.accounts.filter(
    (account) => account.status !== MerchantAccountStatus.IN_ACTIVE
  );

  const cashBalances = useMemo(() => {
    return getCashBalance(accounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  return { accounts, cashBalances, isLoading, error };
};

export default useCashBalance;
