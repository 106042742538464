import { currencyFormatter } from "utils/common";

const AccountCard = ({ balance, dataTestId }: any) => {
  return (
    <div
      data-testid={dataTestId}
      className="flex flex-col gap-2 px-4 py-5 rounded-md"
    >
      <span className="text-2xl font-bold ">
        {currencyFormatter(balance.totalAmount, balance.currency)}
      </span>
      <span>
        <p className="text-sm text-green-900">
          Across <span className="font-bold">{balance.totalCount}</span>{" "}
          account(s)
        </p>
      </span>
    </div>
  );
};

export default AccountCard;
