import { Metadata } from "@nestcoinco/onboard-api-gateway-api-client";

interface CurrencyConfig {
  position: "prefix" | "suffix";
  symbol: string;
  decimal?: number;
}

const symbolMap: Record<string, CurrencyConfig> = {
  USDX: {
    position: "prefix",
    symbol: "$",
    decimal: 4,
  },
  USD: {
    position: "prefix",
    symbol: "$",
    decimal: 2,
  },
  NGN: {
    position: "prefix",
    symbol: "₦",
    decimal: 2,
  },
};

export const currencyFormatter = (val: string | number, symbol = "") => {
  let expr = new RegExp("(\\d)(?=(\\d{3})+\\.)", "g");
  let formattedAmount: string | undefined;
  const config = symbolMap[symbol] || {
    position: symbol.length > 3 ? "suffix" : "prefix",
    symbol,
    decimal: 2,
  };

  if (config.decimal) {
    val = Number(val).toFixed(config.decimal);
  }

  let str = val.toString();
  if (/[^\d.]/.test(str)) {
    formattedAmount = "0";
  } else if (/\./.test(str)) {
    formattedAmount = str.replace(expr, "$1,");
  } else {
    let final = str + ".";
    final = final.replace(expr, "$1,");
    formattedAmount = final.slice(0, final.length - 1);
  }

  return config.position === "prefix"
    ? `${config.symbol}${formattedAmount}`
    : `${formattedAmount}${config.symbol}`;
};

export const getValueFromMetadata = (name: string, metadata: Metadata[]) => {
  // loop metadata
  const data = metadata.find((data) => {
    return data.name === name;
  });
  return data?.value;
};
