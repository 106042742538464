import PaymentAccountsTable from "modules/accounts/PaymentAccountsTable";
import payoutService from "api/payoutService";
import Loader from "modules/common/components/Icons/Loader";
import AccountCardContainer from "modules/common/components/Cards/AccountCardContainer";
import ErrorContainer from "modules/common/components/ErrorContainer/ErrorContainer";
import { useEffect, useState } from "react";
import { MerchantAccountListResponse } from "@nestcoinco/onboard-api-gateway-api-client";
import Pagination from "modules/common/components/Pagination/Pagination";
import cn from "classnames";
import useCashBalance from "modules/common/hooks/useCashBalance";

const Accounts = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [accounts, setAccount] = useState<MerchantAccountListResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const { cashBalances } = useCashBalance();

  useEffect(() => {
    setIsLoading(true);
    payoutService
      .getAccounts({
        page: currentPage,
        size: pageSize,
      })
      .then((data) => {
        setAccount(data as any);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, pageSize]);

  if (error) {
    return (
      <ErrorContainer errorTitle="500" errorMessage="Error fetching accounts" />
    );
  }

  return (
    <div>
      <>
        <section
          className={cn(
            "bg-white rounded-[10px] p-6 py-10 mb-16 h-[200px] overflow-y-hidden",
            cashBalances?.length! >= 4 ? "!h-[296px]" : ""
          )}
        >
          <AccountCardContainer />
        </section>

        <div className="mb-6 bg-white pt-4">
          {isLoading ? (
            <div className="flex justify-center">
              <Loader stroke="black" />
            </div>
          ) : (
            <>
              <div className="overflow-x-auto">
                <PaymentAccountsTable accounts={accounts?.accounts} />
              </div>
              <div className="py-6">
                <Pagination
                  size={pageSize}
                  totalItems={accounts?.totalItems}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default Accounts;
